<template>
    <div class="cover slider" :class="{ isLarg: imgIsClicked }">
        <div v-if="imgIsClicked" class="close-icon" @click="imgClicked">
            <font-awesome-icon :icon="['fas', 'times']" />
        </div>
        <div class="main-img">
            <img :src="images[sliderNo]" alt="Project logo" class="cursor-pointer" @click="imgClicked()" />
        </div>
        <p class="title">{{ title }} @ {{location}} ({{ status }})</p>
        <div class="details">
            <p v-if="imgIsClicked && project_description != undefined">{{ project_description }}</p>
        </div>
        <div v-if="imgIsClicked && status === 'Completed'" class="details">
            <p v-if="segments.length > 0">Segment: {{ segments[0].name }}</p>
            <p>Date: {{ date }}</p>
        </div>
        <div v-if="imgIsClicked" class="arrow">
            <div v-if="sliderNo > 0" class="back" @click="prevImg">
                <font-awesome-icon :icon="['fas', 'angle-left']" />
            </div>
            <div v-if="sliderNo < images.length - 1" class="next" @click="nextImg">
                <font-awesome-icon :icon="['fas', 'angle-right']" />
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    props: [
        'id', 
        'images', 
        'title', 
        'project', 
        'status', 
        'location', 
        'segments', 
        'date', 
        'project_description'
    ],
    data() {
        return {
            sliderNo: 0,
            imgIsClicked: false
        };
    },
    methods: {
        imgClicked() {
            if (this.imgIsClicked) {
                this.sliderNo = 0; // Reset to the first image when exiting large view
            }
            this.imgIsClicked = !this.imgIsClicked;
        },
        prevImg() {
            if (this.sliderNo > 0) {
                this.sliderNo -= 1;
            }
        },
        nextImg() {
            if (this.sliderNo < this.images.length - 1) {
                this.sliderNo += 1;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.slider {
    &.isLarg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 10%;
        box-sizing: border-box;
        flex-direction: column;
        z-index: 10;

        .main-img {
            height: 100%;
            object-fit: contain;
        }
        .title {
            color: white;
            font-size: 1.5rem;
            margin: 1rem 0;
        }

        .details {
            color: white;
            font-size: 1rem;
            margin: 0.5rem 0;

            p {
                margin: 0.2rem 0;
            }
        }

        &:hover {
            transform: none; /* Disable hover effect when large */
        }
    }

    &:hover {
        transform: scale(1.1); /* Hover effect for non-large view */
    }

    .main-img {
        width: 100%;
        height: 150px;
        border-radius: 15px;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .close-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 1.5rem;
        color: white;
        cursor: pointer;
        z-index: 30;

        &:hover {
            color: $alt_3_color;
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        z-index: 20;

        .back,
        .next {
            border: 1px solid $alt_3_color;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            cursor: pointer;
            text-align: center;
            background-color: $alt_2_color;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                width: 45px;
                height: 45px;
            }

            svg {
                font-weight: bold;
                color: $alt_3_color;
            }
        }

        .back {
            left: 2%;
        }

        .next {
            right: 2%;
        }
    }

    .title {
        color: $main_color;
        text-transform: uppercase;
        font-weight: normal;
        font-size: medium;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    .description {
        color: $alt_color;
        // text-align: justify;

        line-height: 25px;

        @media (max-width: 767px) {
            font-size: 12px;
        }
    }

    .review {
        color: $alt_color;
    }

    .back,
    .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid $alt_3_color;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        cursor: pointer;
        text-align: center;
        background-color: $alt_2_color;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            width: 30px;
            height: 30px;
            transform: translateY(-50%);

        }

        svg {
            font-weight: bold;
            margin: auto;
            color: $alt_3_color;
        }
    }

    .back {
        left: 15%;

        @media (max-width: 767px) {
            left: 5%;
        }
    }

    .next {
        right: 15%;

        @media (max-width: 767px) {
            right: 5%;
        }
    }
}
</style>